import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

export const faqRoute: Route = {
  path: 'faq',
  children: [
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    {
      path: ':id',
      data: { title: 'FAQ Library' },
      loadComponent: () =>
        import('./faq-client-library/faq-client-library.component').then(
          (m) => m.FaqClientLibraryComponent
        ),
    },
  ],
};

const routes: Routes = [
  {
    path: 'api',
    children: [
      { path: '', redirectTo: 'index', pathMatch: 'full' },
      {
        path: ':id',
        data: { title: 'API Library' },
        loadComponent: () =>
          import('./api-client-library/api-client-library.component').then(
            (m) => m.ApiClientLibraryComponent
          ),
      },
    ],
  },
  {
    path: 'db',
    children: [
      { path: '', redirectTo: 'index', pathMatch: 'full' },
      {
        path: ':id',
        data: { title: 'Database Library' },
        loadComponent: () =>
          import('./db-client-library/db-client-library.component').then(
            (m) => m.DbClientLibraryComponent
          ),
      },
    ],
  },
  faqRoute,
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientLibrariesRoutingModule {}
