import { Component, DestroyRef, computed, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UtilityService } from '@Services/utility.service';
import { environment } from '@environments/environment';
import { AppService } from '@Services/app.service';
import { SearchService } from '@Shared/components/search/search-extras/search.service';
import { ChatHelpDeskService } from '@Shared/components/page-icons/chat-help-desk/chat-help-desk.service';
import { TranslationService } from '@Services/translation.service';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { UserService } from '@Services/user.service';
import { CacheUpdaterService } from '@Services/cache/cache-updater.service';
import { AppLocalCacheService } from '@Services/cache/local-cache.service';
import { UserActivityService } from '@Services/user-activity.service';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { EUA } from '@User/user-activity/ua-extras/user-activity.model';
import { ConnectionService } from 'ng-connection-service';
import { VersionService } from '@Reusables/reusable-pages/version/version.service';
import { WindowSecurityService } from '@Services/window-security.service';
import { ThemeService } from '@Services/theme.service';
import { ServiceWorkerService } from '@Services/service-worker/service-worker.service';
import { ModuleThemeSetupService } from '@Shared/components/module-theme-setup/module-theme-setup.service';
import { UsermenuService } from '@User/usermenu/usermenu-extras/usermenu.service';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';
import { AppRouteService, InputFormatService, LoaderService } from 'ets-fe-ng-sdk';
import { TranslationGlossaryService } from 'projects/evolutics-client-ui/src/app/Life/Setup/translation/translation-extras/translation-glossary.service';
import { ToastNotificationsService } from '@serene-dev/toast-notifications';
import { UtilityService as US } from 'ets-fe-ng-sdk';
import { MessageService } from './Reusables/reusable-pages/messages/message.service';

@Component({
  template: '',
})
export class SharedAppComponent {
  protected readonly appRouteService = inject(AppRouteService);
  protected readonly appS = inject(AppService);
  protected readonly authService = inject(AuthenticationService);
  protected readonly chatHelpDeskService = inject(ChatHelpDeskService);
  protected readonly connectionService = inject(ConnectionService);
  protected readonly cuS = inject(CacheUpdaterService);
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly messageService = inject(MessageService);
  protected readonly inputFormatService = inject(InputFormatService);
  protected readonly lcS = inject(AppLocalCacheService);
  protected readonly matDialog = inject(MatDialog);
  protected readonly mtsS = inject(ModuleThemeSetupService);
  protected readonly pS = inject(LoaderService);
  protected readonly route = inject(ActivatedRoute);
  protected readonly router = inject(Router);
  protected readonly searchS = inject(SearchService);
  protected readonly snackBar = inject(MatSnackBar);
  protected readonly sdkUtilityS = inject(US);
  protected readonly swService = inject(ServiceWorkerService);
  protected readonly themeService = inject(ThemeService);
  protected readonly toastNotificationsService = inject(ToastNotificationsService);
  protected readonly transGlossary = inject(TranslationGlossaryService);
  protected readonly translateService = inject(TranslationService);
  protected readonly uaS = inject(UserActivityService);
  protected readonly uS = inject(UtilityService);
  protected readonly userMS = inject(UsermenuService);
  protected readonly userService = inject(UserService);
  protected readonly vS = inject(VersionService);
  protected readonly windowSecurityService = inject(WindowSecurityService);

  routeSub: Subscription;
  readonly screenHeight = computed(() => {
    console.log('checked screen height');
    this.uS.isMobileSignal();
    return window.innerHeight;
  });
  constructor() {
    console.log('Loaded at ', new Date());
    console.log('ALLOWED SYSTEMS', environment.activeSystems);
    console.log('new update');
    this.windowSecurityService.startTabCheck(environment.singleTabUsage);
    this.themeService.setup();
    this.destroyRef.onDestroy(() => {
      this.appS.pageName = '';
      this.routeSub.unsubscribe();
    });
    this.sdkUtilityS.info = this.uS.info;
  }
  async ngOnInit() {
    let firstTime = false;
    this.messageService.getLatestNotifications();
    this.connectionService
      .monitor()
      .pipe(filter((x) => firstTime))
      .subscribe((isConnected) => {
        firstTime = true;
        if (!isConnected) {
          this.uS.notify('You have lost internet connectivity', 0, 500000, 'Close');
        } else {
          this.uS.notify('You are online now', 1, 5000, 'Close');
        }
      });
  }

  ngAfterViewInit() {
    this.routeSub = this.appRouteService.observable
      .pipe(
        tap((route) => {
          environment.activatedRoute = route;
        }),
        map((route) => route.snapshot),
      )
      .subscribe((snapshot) => {
        environment.currentBusLine = this.appS.busLine as any;
        const title = snapshot.data?.titleFactory
          ? snapshot.data.titleFactory(snapshot)
          : snapshot.data?.title;
        this.appS.pageName = title;
        environment.pageTitle = title;
        this.uS.clearSubsToCloseOnRoute();
        // environment.wideScreen = null;
        this.inputFormatService.pageNoformat = snapshot.data?.pageNoFormat;
        PermissionManager.routeMIDs = [];
        // if (environment.alreadyOpened)
        this.uaS.add({ code: EUA.visited, pageTitle: title });
        // this.snackBar.dismiss();
        this.matDialog.closeAll();
        if (this.uS.$('.modal')?.modal) this.uS.$('.modal')?.modal('hide');
        // this.tS.setActive(params.theme as ThemeName);
        // this.app.buildBreadCrumb(this.activatedRoute.root);
        // console.log(location.pathname,params?.title)
        // this.uS.scrollToTop();
        this.chatHelpDeskService.reload();

        // setTimeout(() => {
        //   fieldToLabelMap[x.name]
        //   console.log(
        //     this.inputs.map((x) => `${x.name as string}: '${x.label as string}'`).join(',')
        //   );
        // }, 2000);
      });
  }
}
