export enum ESalesSystem {
  admin = 'admin',
  report = 'report',
  calendar = 'calendar',
  sales = 'sales',
  teams = 'teams',
  client = 'client',
  home = 'home',
  workflow = 'workflow',
  finance = 'finance',
}
