import { Injectable } from '@angular/core';
import { IGetQuery, SDKBaseService } from 'ets-fe-ng-sdk';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends SDKBaseService {
  protected baseURL: string = environment.messagingBaseURL;

  searchNotifications(query: IGetQuery) {
    return this.get(`v1/notification/search`, query);
  }
  searchNotificationReceivers(query?: IGetQuery<{ assignedTo: string }>) {
    return this.get(`v1/notification/receiver/search`, {
      ...query,
      assignedTo: query?.assignedTo || environment.userName,
    });
  }

  getLatestNotifications() {
    this.searchNotificationReceivers().toPromise();
  }
}
