import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardBoxViewerComponent } from './card-box-viewer/card-box-viewer.component';
import { ChartViewerComponent } from './chart-viewer/chart-viewer.component';
import { NgChartDirective } from '@Shared/directives/ng-chart/ng-chart.directive';
import { SharedModule } from '@Shared/shared.module';
import { ComponentsModule } from '@Shared/components/core/button/components.module';
import { TableViewerComponent } from './table-viewer/table-viewer.component';
import { BrowserModule } from '@angular/platform-browser';
import { BareChartViewerComponent } from './bare-chart-viewer/bare-chart-viewer.component';
import { TablePlainComponent } from 'ets-fe-ng-sdk';
import { AddEventComponent } from './calendar/add-event/add-event.component';
import { SuiIconsComponent } from './sui-icons/sui-icons.component';
import { DefineColumnsComponent } from './table-viewer/define-columns/define-columns.component';

const COMPS = [
  CardBoxViewerComponent,
  ChartViewerComponent,
  TableViewerComponent,
  BareChartViewerComponent,
  AddEventComponent,
  SuiIconsComponent,
  DefineColumnsComponent,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    NgChartDirective,
    TablePlainComponent,
    ...COMPS,
  ],
  exports: [...COMPS],
})
export class SalesSharedModule {}
