<div class="d-flex justify-content-between">
  <h5 mat-dialog-title>
    {{ 'Define Columns to Display' | appTranslate | async }}
  </h5>
  <app-btn type="danger-outline" icon="close"></app-btn>
</div>
<mat-dialog-content>


</mat-dialog-content>
