import { Component, OnInit } from '@angular/core';
import { EMenuType } from '@Shared/models/index.model';
import { environment } from '@environments/environment';
import { SalesAppService } from 'projects/evolutics-sales-ui/src/app/services/sales-app.service';
import { SalesMenuItem } from 'projects/evolutics-sales-ui/src/app/models/sales.IMenuItem';
import { UtilityService } from '@Services/utility.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { TeamsService } from 'projects/evolutics-sales-ui/src/app/teams/teams.service';
import { HomeService } from 'projects/evolutics-sales-ui/src/app/home/home.service';
import { AppService } from '@Services/app.service';
import { lastValueFrom } from 'rxjs';
import { IMenuItem } from '@Shared/models/IMenuItem';
import { TranslatePipe } from '../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { BtnComponent, AutocompleteComponent, configForms } from 'ets-fe-ng-sdk';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { VerticalNavComponent } from '../vertical-nav/vertical-nav.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { IWebUser } from '@Authentication/web-user/web-user-authentication.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    VerticalNavComponent,
    NgClass,
    AutocompleteComponent,
    BtnComponent,
    NgIf,
    RouterLink,
    RouterOutlet,
    AsyncPipe,
    TranslatePipe,
    FontAwesomeModule,
  ],
})
export class MainComponent implements OnInit {
  title = 'Sales UI';
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  subMenus: IMenuItem[] = [];
  user: IWebUser;
  searchForm = new FormGroup({
    memberCode: configForms.required(),
  });
  members: any[];
  name: string;

  get hideHorizontalMenu() {
    return environment.menuLayout == EMenuType.vertical;
  }
  constructor(
    public sappS: SalesAppService,
    public uS: UtilityService,
    public appS: AppService,
    private route: ActivatedRoute,
    private authS: AuthenticationService,
    private teamS: TeamsService,
    public homeS: HomeService,
    public router: Router,
  ) {}

  back() {
    this.uS.back();
  }
  async ngOnInit(): Promise<void> {
    this.user = this.sappS.webUser;
    this.name = this.sappS.webUser.username;
    // debugger;
    this.sappS.showTopSearch = this.route.snapshot.data.showSearch ? true : false;
    await this.getMembers();
  }
  showSubs(subs: IMenuItem[]) {
    this.subMenus = subs;
    console.log('Subs are', this.subMenus);
  }
  setCurrentMenu(item: SalesMenuItem) {
    console.log(item);
    //this.sappS.set(item.system);
  }
  signOut() {
    this.authS.logout('/');
  }

  async getMembers() {
    const webUser = this.sappS.webUser;
    console.log('Default Web user', webUser);
    const team = (await lastValueFrom(this.teamS.searchMember({ memberCode: webUser.memberCode })))[0];
    const members = team.code ? await lastValueFrom(this.teamS.searchMemberByteamCode(team.code)) : [];
    members.forEach((m) => {
      m['code'] = m.memberCode;
      m['description'] = m.memberName;
    });
    // debugger;
    this.members = members.sort2('code', true);
  }

  search() {
    const selectedMember = this.members.find((m) => m.memberCode === this.searchForm.value.memberCode);
    if (selectedMember.teamCat === 'A') {
      this.homeS.agentNo = selectedMember.primaryCode;
      this.homeS.teamCategory = 'A';
    }
    if (selectedMember.teamCat === 'R') {
      this.homeS.userCode = selectedMember.primaryCode;
      this.homeS.teamCategory = 'R';
    }
    this.homeS.reloadDashboard.next(true);
  }

  reset() {
    this.searchForm.reset();
    this.homeS.resetDashboard();
  }
}
