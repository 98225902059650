import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AvailableGuard } from './guards/available.guard';
import { MainComponent } from './layout/main/main.component';
import { ESalesSystem } from './models/sales.model';
import { faqRoute } from 'projects/evolutics-admin-ui/src/app/client-libraries/client-libraries-routing.module';
import { appRoutes } from 'projects/evolutics-client-ui/src/app/configs/app-routes-configs/app-routes.config';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: appRoutes.auth._,
    loadChildren: () =>
      import('../../../evolutics-shared-lib/src/lib/Authentication/web-user/web-user-auth.routing'),
  },
  {
    path: 'login',
    redirectTo: appRoutes.auth._ + '/login',
  },
  {
    path: '',
    canActivateChild: [AuthGuard, AvailableGuard],
    children: [
      {
        path: 'home',
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'dashboard',
            component: MainComponent,
            data: {
              system: ESalesSystem.home,
              showSearch: true,
            },
            loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
          },
        ],
      },

      {
        path: 'client',
        component: MainComponent,
        data: {
          system: ESalesSystem.client,
        },
        loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
      },

      {
        path: 'teams',
        component: MainComponent,
        data: {
          system: ESalesSystem.teams,
        },
        loadChildren: () => import('./teams/teams.module').then((m) => m.TeamsModule),
      },

      {
        path: 'sales',
        component: MainComponent,
        data: {
          system: ESalesSystem.sales,
        },
        loadChildren: () => import('./sales/sales.module').then((m) => m.SalesModule),
      },
      {
        path: 'report',
        component: MainComponent,
        data: {
          system: ESalesSystem.report,
        },
        loadChildren: () => import('./report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'calendar',
        component: MainComponent,
        data: {
          system: ESalesSystem.calendar,
        },
        loadChildren: () => import('./calendar/calendar.module').then((m) => m.AppCalendarModule),
      },

      {
        path: 'admin',
        component: MainComponent,
        data: {
          system: ESalesSystem.admin,
        },
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },

      {
        path: 'workflow',
        component: MainComponent,
        data: {
          system: ESalesSystem.workflow,
        },
        loadChildren: () => import('./workflow/workflow.module').then((m) => m.WorkflowModule),
      },

      {
        path: 'finance',
        component: MainComponent,
        data: {
          system: ESalesSystem.finance,
        },
        loadChildren: () => import('./finance/finance.module').then((m) => m.FinanceModule),
      },

      // {
      //   path: '',
      //   component: FormLayoutComponent,
      //   loadChildren: () =>
      //     import('projects/evolutics-client-ui/src/app/Life/policy-desk/policy-desk.module').then(
      //       (m) => m.PolicyDeskModule
      //     ),
      // },
      {
        path: 'profile',
        component: MainComponent,
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },

      {
        path: 'help',
        redirectTo: 'faq',
      },
      faqRoute,
      {
        path: '',
        loadChildren: () =>
          import('@Reusables/reusable-pages/extra-pages/extra-pages.module').then((m) => m.ExtraPagesModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
