import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from '@environments/environment';
import { Config } from 'ets-fe-ng-sdk';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { SalesAppService } from '../services/sales-app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    public router: Router,
    private authS: AuthenticationService,
    private sappS: SalesAppService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.guard();
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.guard();
  }
  guard() {
    if (this.sappS.isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: { r: location.href.replace(location.origin, '') },
      });
      return false;
    }
  }
}
