<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" [ngClass]="{ fullScreen }">
  <mat-sidenav-container
    [id]="id"
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav
      class="sui-dy-bg"
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
      [hidden]="hideMenu">
      <div class="py-2">
        <div class="d-flex justify-content-center">
          <!-- <button mat-icon-button (click)="snav.toggle()">
            <mat-icon>menu</mat-icon>
          </button> -->
          <div class="text-center">
            <div class="mt-4">
              <a routerlink="/" class="navbar-brand text-primary text-bold m-auto logo-name" href="/">
                <img alt="" height="30" src="../../../../../../assets/img/evologoNew.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 px-2">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="expandable-node">
            <div class="mat-tree-node">
              <button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name"
                class="menu-btn level{{ node.level }}">
                <div class="row">
                  <div class="col">
                    <span style="width: 20px" class="{{ node.icon }} me-3 ms-1"></span>
                    <span>{{ node.label | appTranslate | async }}</span>
                  </div>
                  <div class="col-auto">
                    <span
                      class="fa {{
                        treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right'
                      }} ms-1"></span>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <div
                [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                role="group"
                class="group p-0">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </div>
          </mat-nested-tree-node>
          <mat-tree-node
            *matTreeNodeDef="let node; when: shouldShow"
            matTreeNodeToggle
            class="single-node level{{ node.level }}"
            [routerLink]="node.link"
            routerLinkActive="is-active">
            <a class="d-block level{{ node.level }} w-100 ">
              <span class="{{ node.icon }} me-3 ms-1"></span>
              {{ node.label | appTranslate | async }}
            </a>
          </mat-tree-node>
          <mat-tree-node
            *matTreeNodeDef="let node"
            matTreeNodeToggle
            class="single-node restricted level{{ node.level }}"></mat-tree-node>
        </mat-tree>
      </div>
      @if (isHome) {
        <div class="profile">
          <button mat-button [matMenuTriggerFor]="aboveMenu">
            <img alt="Avatar" class="avatar me-2" imageType="pp" [imageLoader]="user?.users?.imageUrl" />
            {{ user?.users?.firstName }} {{ user?.users?.lastName }}
          </button>
          <mat-menu #aboveMenu="matMenu" yPosition="above" xPosition="after" class="profile">
            <button mat-menu-item routerLink="profile">
              <a class="pointer">{{ 'Profile' | appTranslate | async }}</a>
            </button>
            <button mat-menu-item (click)="signOut()">
              <a class="pointer">{{ 'Sign out' | appTranslate | async }}</a>
            </button>
            <button mat-menu-item>
              <a class="d-block" routerLink="{{ helpLink }}" target="_blank">
                {{ 'Help' | appTranslate | async }}
              </a>
            </button>
          </mat-menu>
        </div>
      }
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
