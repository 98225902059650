import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { TableCol } from '@Shared/models/index.model';
import { TranslatePipe } from '../../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { BtnComponent } from 'ets-fe-ng-sdk';

@Component({
    selector: 'app-define-columns',
    templateUrl: './define-columns.component.html',
    styleUrls: ['./define-columns.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        BtnComponent,
        MatDialogContent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class DefineColumnsComponent implements OnInit {
  columns: TableCol[];
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<DefineColumnsComponent>
  ) {}

  ngOnInit(): void {
    this.columns = this.data.columns;
  }


}
