<sui-vertical-nav [menuItems]="sappS.salesTopMenu" [fullScreen]="true" [title]="title" [showSearch]="true">
  <div
    class="container-fluid py-3 main-bg"
    style="min-height: 100vh"
    [ngClass]="{ vertical: hideHorizontalMenu }"
    #dashboard>
    <div class="row g-3 align-items-center">
      <div class="col">
        <div class="header br-10 sui-dy-bg-light mat-elevation-z2">
          @if (sappS?.showTopSearch) {
            <div class="row search-box gx-2">
              <div class="col-md-7">
                <app-autocomplete
                  placeholder="My Team Members"
                  type="select"
                  name="memberCode"
                  [stacked]="false"
                  [options]="members"
                  valueField="memberCode"
                  labelType="cd"
                  [form]="searchForm"></app-autocomplete>
              </div>
              <div class="col-md-auto">
                <app-btn
                  type="clear"
                  text="Load Dashboard"
                  mclass="btn btn-light"
                  icon="search"
                  [form]="searchForm"
                  (mclick)="search()"></app-btn>
              </div>
              <div class="col-md-auto">
                <app-btn type="danger" icon="close" (mclick)="reset()"></app-btn>
              </div>
            </div>
          }
          <div class="header-menu">
            <!-- <a class="menu-link is-active" href="#">Apps</a> -->
            <!-- <ng-container *ngFor="let item of sappS.salesBottomMenu">

                <a class="menu-link" [matMenuTriggerFor]="mainMenu">
                  <sui-icon [name]="item.icon"></sui-icon> <span> {{ item.label }}</span>
                </a>
                <mat-menu #mainMenu="matMenu">
                  @for (item2 of item.subs; track item2) {
  <a mat-menu-item [routerLink]="item2.link" routerLinkActive="is-active" class="menu-link"
                   >
                    {{ item2.label| appTranslate | async }}
                  </a>
}
                </mat-menu>

} @else {

                <a [routerLink]="item.link" routerLinkActive="is-active" class="menu-link">
                  <sui-icon [name]="item.icon"></sui-icon> <span> {{ item.label }}</span>
                </a>
              </ng-template>
            </ng-container> -->
            <div class="col-12" *ngIf="!homeS.hideBackBtn">
              <div class="d-flex align-items-center p-2 br-10">
                <!-- <div class="">
                  <app-btn (mclick)="back()" type="clear" text="Back" [mini]="true" icon="previous" [iconBtn]="false" />
                </div> -->
                <p class="arrow m-0 pointer text-light" (click)="back()">
                  <fa-icon [icon]="faArrowAltCircleLeft" size="2x"></fa-icon>
                </p>
                <div class="border-start ms-3 px-2 text-light">
                  {{ appS.pageName }}
                </div>
              </div>
            </div>
          </div>
          <div class="header-profile">
            <div class="notification">
              <!-- <span class="notification-number">3</span> -->
              <svg
                viewBox="0 0 24 24"
                fill="#fff"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-bell">
                <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0" />
              </svg>
            </div>
            <div class="dropdown">
              <img class="profile-img dropbtn" [src]="user?.imageUrl || './assets/img/avatar.png'" />
              <!-- <p class="text-primary text-bold m-auto px-2 inline font-14">{{name | slice: 0:15}}{{ name.length > 15 ? '...'
            : ''}}</p> -->
              <div class="dropdown-content p-2">
                <a routerLink="/profile">{{ 'Profile' | appTranslate | async }}</a>
                <a (click)="signOut()">{{ 'Logout' | appTranslate | async }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12" *ngIf="!homeS.hideBackBtn">
        <div class="bg-white d-flex align-items-center p-2 br-10 mat-elevation-z2">
          <div class="">
            <app-btn (mclick)="back()" type="clear" text="Back" [mini]="true" icon="previous" [iconBtn]="false" />
          </div>
          <div class="text-primary border-start px-2 f-600">
            {{appS.pageName}}
          </div>
        </div>
      </div>
}
      <div class="col-12">
        <div class="" [ngClass]="{'container':!homeS.wideView}" >

          <div class="bg-white p-4 br-10 mat-elevation-z2">
      </div> -->
      <div class="col-12">
        <div class="" [ngClass]="{ container: !homeS.wideView }">
          <div class="p-4">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
    <!-- <ng-container *ngIf="sappS.salesBottomMenu.length > 0">
      <div class="my-2">
        <div class="row m-0 align-items-center">
          <div class="col overflow-x-auto" PSBox>
            <nav class="navbar navbar-expand-sm bg-dark navbar-dark ">
              <div class="navbar-collapse  navbars" id="navbar1">
                <ul class="navbar-nav justify-content-around mx-auto">
                  <li class="{{item.hasSub?'dropdown':''}} nav-item mx-1 " *ngFor="let item of sappS.salesBottomMenu">
                    <app-menu-item [menuItem]="item|toAny"></app-menu-item>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </ng-container> -->
  </div>
</sui-vertical-nav>
