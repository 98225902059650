<div class="menu  bg-dark {{sappS.system}}">
  <div class="row m-0 align-items-center">
    <div class="col overflow-x-auto" PSBox>
      <nav class="navbar navbar-expand-sm bg-dark navbar-dark ">
        <div class="navbar-collapse  navbars" id="navbar1">
          @if (!system) {
          <ul class="navbar-nav justify-content-around mx-auto">
            @for (item of sappS.salesBottomMenu ; track tractById($index, item)) {
            <li class="{{item.hasSub?'dropdown':''}} nav-item mx-1 ">
              <app-menu-item [menuItem]="item|toAny"></app-menu-item>
            </li>
            }
          </ul>
          }
        </div>
      </nav>
    </div>
    <div class="col-auto" style="min-height: 60px;">
      <ul class="align-items-center d-flex m-0 p-0 py-2 extra-menu">
        <li class="nav-item dropdown mx-1">
          <a aria-expanded="false" class="nav-link dropdown-toggle p-0 px-md-0 px-lg-0 px-sm-0 px-xl-1"
            data-bs-toggle="dropdown" href="#" role="button">
            <img alt="Avatar" class="avatar" imageType="pp" [imageLoader]=" user?.users?.imageUrl ">
            <b class="caret"></b></a>
          <ul class="dropdown-menu dropdown-menu-dark">
            <li><a class="dropdown-item underline" routerLink="profile">{{'Profile'|appTranslate|async}}</a></li>
            <li><a (click)="signOut()" class="dropdown-item underline pointer">{{'Sign out'|appTranslate|async}}</a>
            </li>
            <li><a class="dropdown-item" href="{{helpLink}}" target="_blank">{{'Help'|appTranslate|async}}</a></li>
          </ul>
        </li>

      </ul>
    </div>
  </div>
</div>
