import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderModule } from 'ets-fe-ng-sdk';
import { SearchModule } from '@Shared/components/search/search.module';
import { DirectivesModule } from '@Shared/directives/index.directive';
import { UtilityPipesModule } from 'ets-fe-ng-sdk';
import { TopNavComponent } from './top-nav/top-nav.component';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationModule } from '@Layout/navigation/navigation.module';
import { MainComponent } from './main/main.component';
import { VerticalNavComponent } from './vertical-nav/vertical-nav.component';
import { SalesSharedModule } from '../shared/sales-shared.module';
import { SharedModule } from '@Shared/shared.module';
import { IsNavMenuEnabledPipe } from '@Shared/pipes/utility.pipe';

const COMPS = [
  TopNavComponent,
  BottomNavComponent,
  NavigationComponent,
  VerticalNavComponent,
  MainComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule,
        UtilityPipesModule,
        LoaderModule,
        SearchModule,
        DirectivesModule,
        MatMenuModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatToolbarModule,
        NavigationModule,
        SalesSharedModule,
        SharedModule,
        ...COMPS
    ],
    exports: [COMPS],
    providers: [IsNavMenuEnabledPipe]
})
export class SUILayoutModule {}
