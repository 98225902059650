import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { SearchService } from '@Shared/components/search/search-extras/search.service';
import { SalesAppService } from '../services/sales-app.service';

@Injectable({
  providedIn: 'root',
})
export class AvailableGuard  {
  constructor(
    public appS: SalesAppService,
    public router: Router,
    public sS: SearchService
  ) {}
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard(route, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard(route, state);
  }

  private _guard(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // debugger;
    if (route.data.system) {
      this.appS.system = route.data.system;
      this.appS.setCurrentTopMenu(route.data.system);
      this.sS.setSystemMenuIndex(route.data.system);
      this.appS.setMetaTheme();
    }
    if (!environment.activeSystems?.length) return true;
    else if (
      environment.activeSystems.includes(route.data.system || this.appS.system)
    )
      return true;
    else {
      this.router.navigateByUrl(this.appS.getFirstAvailableSystem.appRoute.pub);
      return false;
    }
  }
}
